/*
 * Import the fonts you want to use
 */
 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:wght@600&display=swap');

html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* global SASS colors */
:root {
  --color-neutral-900: 23 23 23;
  --color-neutral-800: 35 35 35;
  --color-neutral-700: 44 44 44;
  --color-neutral-600: 68 68 68;
  --color-neutral-500: 85 86 88;
  --color-neutral-400: 115 115 115;
  --color-neutral-300: 150 150 150;
  --color-neutral-200: 196 196 196;
  --color-neutral-100: 234 234 234;
  --color-neutral-50: 248 248 248;

  --color-primary-900: 59 27 18;
  --color-primary-800: 105 33 13;
  --color-primary-700: 171 43 7;
  --color-primary-600: 208 48 4;
  --color-primary-500: 255 55 0;
  --color-primary-400: 254 107 69;
  --color-primary-300: 255 135 102;
  --color-primary-200: 255 175 153;
  --color-primary-100: 255 215 204;
  --color-primary-50: 235 245 242;

  --color-secondary-900: 66 42 26;
  --color-secondary-800: 110 63 32;
  --color-secondary-700: 155 83 37;
  --color-secondary-600: 199 104 43;
  --color-secondary-500: 244 125 48;
  --color-secondary-400: 246 151 89;
  --color-secondary-300: 248 177 131;
  --color-secondary-200: 251 203 172;
  --color-secondary-100: 253 229 214;
  --color-secondary-50: 254 242 234;

  --color-success-900: 19 49 39;
  --color-success-800: 16 77 56;
  --color-success-700: 14 104 74;
  --color-success-600: 11 132 91;
  --color-success-500: 9 160 109;
  --color-success-400: 50 186 124;
  --color-success-300: 112 207 163;
  --color-success-200: 152 220 189;
  --color-success-100: 194 234 216;
  --color-success-50: 235 248 242;

  --color-warning-900: 51 47 27;
  --color-warning-800: 99 75 23;
  --color-warning-700: 148 103 19;
  --color-warning-600: 196 130 15;
  --color-warning-500: 245 158 11;
  --color-warning-400: 251 191 36;
  --color-warning-300: 252 211 77;
  --color-warning-200: 253 230 138;
  --color-warning-100: 254 243 199;
  --color-warning-50: 255 251 235;

  --color-danger-900: 127 29 29;
  --color-danger-800: 153 27 27;
  --color-danger-700: 185 28 28;
  --color-danger-600: 220 38 38;
  --color-danger-500: 239 68 68;
  --color-danger-400: 248 113 113;
  --color-danger-300: 252 165 165;
  --color-danger-200: 254 202 202;
  --color-danger-100: 254 226 226;
  --color-danger-50: 254 242 242;

  --color-shades-0: 255 255 255;
  --color-shades-100: 0 0 0;
  --shadesblack: 0 0 0;
  --shadeswhite: 255 255 255;

  --color-accent-orange-default: 239 96 36;
  --color-accent-orange-light: 253 236 229;
  --color-accent-orange-dark: 96 38 14;

  --color-accent-ruby-default: 197 17 98;
  --color-accent-ruby-light: 248 226 236;
  --color-accent-ruby-dark: 79 7 39;

  --color-accent-pink-default: 219 0 255;
  --color-accent-pink-light: 251 224 255;
  --color-accent-pink-dark: 88 0 102;

  --color-accent-violet-default: 143 0 255;
  --color-accent-violet-light: 242 224 255;
  --color-accent-violet-dark: 57 0 102;

  --color-accent-indigo-default: 74 0 233;
  --color-accent-indigo-light: 233 224 252;
  --color-accent-indigo-dark: 30 0 93;

  --color-accent-navy-default: 0 0 119;
  --color-accent-navy-light: 224 224 239;
  --color-accent-navy-dark: 0 0 48;

  --color-accent-blue-default: 0 97 203;
  --color-accent-blue-light: 224 236 249;
  --color-accent-blue-dark: 0 39 81;

  --color-accent-lime-default: 67 160 71;
  --color-accent-lime-light: 232 244 233;
  --color-accent-lime-dark: 27 64 28;

  --color-accent-forest-default: 10 96 73;
  --color-accent-forest-light: 226 236 233;
  --color-accent-forest-dark: 4 38 29;

  // ??
  --color-accent-main: 208 221 40;

  /* global SASS typography */

  /* Important to note that static px values have been converted to rem */
  /* following the font property's shorthand to make the selection more plug and play */
  /* implementation should follow `font: [font-weight optional] [font-size] [font-family] */

  --typography-display1: 4.5rem;
  /* 72px */
  --typography-display2: 3.5rem;
  /* 56px */

  --typography-h1: 3rem;
  /* 48px */
  --typography-h2: 2.75rem;
  /* 44px */
  --typography-h3: 2.5rem;
  /* 40px */
  --typography-h4: 2rem;
  /* 32px */
  --typography-h5: 1.75rem;
  /* 28px */
  --typography-h6: 1.5rem;
  /* 24px */

  --typography-subHeader: 1.25rem;
  /* 20px */

  /* for the underline fonts, it's better practice to manually use the text-decoration property for your given component */
  --typography-p1: 1.125rem;
  /* 18px */
  --typography-p2: 1rem;
  /* 16px */
  --typography-p3: 0.875rem;
  /* 14px */

  --typography-caption: 0.75rem;
  /* 12px */
  --typography-footer: 0.625rem;
  /* 10px */
  --typography-fine-print: 0.5rem;
  /* 8px */

  /* global SASS box-shadows */

  --elevation-xs-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --elevation-s-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  --elevation-m-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  --elevation-l-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  --elevation-xl-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15);

  /* global SASS screen breakpoints */
  /* these will be implemented as the min-width with the media query */
  /* based on tailwindcss recommendations */
  --layout-breakpoints-xl: 1440px;
  --layout-breakpoints-l: 1240px;
  --layout-breakpoints-m: 905px;
  --layout-breakpoints-s: 600px;
  --layout-breakpoints-xs: 475px;



  /* Global Font family  */
  --font-family-display: 'Poppins', sans-serif;
  --font-family-body: 'Inter', sans-serif;
  --font-family-main: 'Inter', sans-serif;
  --font-family-control: 'Inter', sans-serif;

  /* global ButtonComponent configs */
  --button-outlined-outline-width: 1px;

  /* You can add global styles to this file, and also import other style files */
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  /* A class is useful for applying styles to multiple elements without repeating the styles in your stylesheet. */
  .hide-scrollbar {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  --topbar-height: 73px;


  --ad-dropdown-background-color: rgb(var( --color-shades-100))

  --control-text-color: rgb(var(--color-shades-0));


}

$text-display-classes: 'display1', 'display2';
$text-heading-classes: 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subHeader';
$text-small-classes: 'p1', 'p2', 'p3', 'caption', 'footer', 'fine-print';

@each $text-class in $text-display-classes {
  .text-#{$text-class} {
    &,
    &--semibold {
      font-family: var(--font-family-display);
      font-size: var(--typography-#{$text-class});
      line-height: 1.1;
      letter-spacing: -0.5px;
    }
    &--semibold {
      font-weight: 600;
    }
  }
}

@each $text-class in $text-heading-classes {
  .text-#{$text-class} {
    &,
    &--semibold {
      font-family: var(--font-family-display);
      font-size: var(--typography-#{$text-class});
    }
    &--semibold {
      font-weight: 600;
    }
    &--underline {
      text-decoration: underline;
    }
  }
}

@each $text-class in $text-small-classes {
  .text-#{$text-class} {
    &,
    &--underline,
    &--semibold {
      font-size: var(--typography-#{$text-class});
      letter-spacing: 0.25px;
    }
    &--semibold {

      font-weight: 600;
    }
    &--underline {
      text-decoration: underline;
    }
  }
}

.text-h1 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.16;
  }
}

.text-h2 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.27;
  }
}

.text-h3 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.2;
  }
}

.text-h4 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.25;
  }
}

.text-h5 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.28;
  }
}

.text-h6 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.33;
  }
}

.text-subHeader {
  &,
  &--underline,
  &--semibold {
    line-height: 1.2;
  }
}

.text-p1 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.6;
  }
}

.text-p2 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.5;
  }
}

.text-p3 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.4;
  }
}

// .text-caption {
//   &,
//   &--underline,
//   &--semibold {
//     line-height: 1.3;
//   }
// }

.text-footer {
  &,
  &--underline,
  &--semibold {
    line-height: normal;
  }
}

.text-fine-print {
  &,
  &--underline,
  &--semibold {
    line-height: normal;
  }
}



